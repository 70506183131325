export default {

    getProductionSectorsIds(state, obj = {}) {
        
        var newArray = [];
        if(state.production_sectors_id!=undefined && state.production_sectors_id!=null && state.production_sectors_id!=''){
            const array = state.production_sectors_id.toString().split(',');
            array.forEach(element => {
                if(element!=''){
                    newArray.push(parseInt(element));
                }
            });
        }
        return newArray;
    }

}