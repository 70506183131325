const setCidade = ({ commit }, obj) => {
    commit('SET_CIDADE', obj);
}
const setCartao = ({ commit }, cartao) => {
    commit('SET_CARTAO', cartao);
}


export default {
    setCidade,
    setCartao
}