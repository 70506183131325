<template>
  <div class="home">
      
      <b-modal ref="my-modal" hide-footer  hide-header modal-title="">
        <template #default="{ hide }">
          
          <button @click="hide()" class="fecharModal">Fechar</button>

          <a :href="poupup_link"  target="_blank" class="d-block text-center">
            <img :src="poupup" style="max-width:100%; border-radius: 6px;"/>
          </a>
          
        </template>
      </b-modal>
    
      <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      background="#ababab"
      indicators
      controls
      img-width="1024"
      img-height="480"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      >

        <b-carousel-slide v-for="(slide,slideIndex) in slides" :key="slideIndex" :href="slide.link" :img-src="slide.url"></b-carousel-slide>
      
    </b-carousel>

    <div class="container">

      <div class="row">

        <div v-for="(home_quad,index) in titulos_home" :key="index" class="col-md-4 col-12">
          <div  class="row home_quad">
            <div class="col-auto">
              <img :src="home_quad.icone"/>
            </div>
            <div class="col">
              <strong>{{ home_quad.titulo }}</strong>
              <p>{{ home_quad.texto }}</p>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="cartoespensados" style="background-image: url('./bg.png');">
      <div class="container">
        <div class="row align-items-center" style=" margin-bottom: 50px;">
          <div class="col">
            <div class="cartoespensados-titulo">Cartões <strong>pensados em você</strong></div>
          </div>
          <div class="col-auto">
            <img src="iconeshome.png"/>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div v-for="(cartao,index_cartao) in cartoes" :key="index_cartao" class="col-md-4 col-12">
            <div class="cartoespensados-cartao">
              <img :src="cartao.imagem" style="max-width: 100%; border-radius: 10px;"/>
            </div>
            <div class="cartoespensados-nome">{{ cartao.titulo }}</div>
            <div class="cartoespensados-subtitulo">{{ cartao.subtitulo }}</div>
          </div>
        </div>
      </div>
      
    </div>

    <div class="home-rede-credenciada" style="background-image: url('./rede_bg.png');">
      <div class="container">
        <div class="row align-items-center">
          <div class="col">
              <strong>Nossa Rede de Credenciados</strong>
              <p>Uma ampla rede de aceitação para atender nossos usuários, como  restaurantes, lanchonetes, lojas, supermercados e muito mais.
              </p>
              <router-link to="/rede-credenciada" class="saiba-mais">Saiba Mais</router-link>
          </div>
          <div class="col-auto">
            <img src="/rede_mulher.png" class="img-redehome"/>
          </div>
        </div>
      </div>
    </div>


    <div class="comece-a-usar" style="background-image: url('./app_bg.png');">
      <div class="container">
        <div class="row align-items-center">
          <div class="col">
              <div class="comece-a-usar-titulo comece-a-usar-gradiente">Comece a usar agora!</div>
              <p>
                Fique por dentro da nossa rede de credenciados,
                novidades e promoções exclusivas.
                Acompanhe o seu saldo e suas transações.
              </p>
          </div>
          <div class="col-auto">
            <img src="/app_celular.png" class="img-responsive"/>
          </div>
          <div class="col-auto">
            <strong class="comece-a-usar-gradiente">Baixe nosso app <br> no seu celular:</strong>
            <a href="#" class="link-loja"><img src="appstore.png" style="border-radius: 10px;"/></a>
            <a href="#" class="link-loja"><img src="google.png" style="border-radius: 6px;"/></a>
          </div>
        </div>
      </div>
    </div>

    
  <div class="ficou-interessado" style="background-image: url('./ficouinteressado.png');">
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <strong>Ficou interessado? Fale Conosco</strong>
        </div>
        <div class="col-auto">
          <router-link to="/fale-conosco" class="saiba-mais">Fale Conosco</router-link>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      slide: 0,
      sliding: null,
      poupup: '',
      cartoes: [],
      clientes: [],
      poupup_link: null,
      slides: [],
      titulos_home:[
        {
          icone: '/pagamentosimples.png',
          titulo:'Soluções específicas',
          texto:'Para cada situação em sua empresa, temos a solução: Alimentação, Antecipação Salarial, Cartão Presente e muito mais.'
        },
        {
          icone: '/acessopelocelular.png',
          titulo:'Acesso pelo celular e PC',
          texto:'Gerencie de forma simples e rápida os cartões e benefícios dos colaboradores.'
        },
        {
          icone: '/controlesuaconta.png',
          titulo:'Controle a sua conta',
          texto:'Gestão completa do saldo e das transações do seu cartão Mult Vale.'
        }
      ]

    }
  },
  mounted(){
    
    

    this.carregaInfos();

    this.carregaCartoes();
    this.carregaClientes();

  },
  methods: {
    carregaInfos(){

      this.$axios.get(process.env.VUE_APP_URL+'infos?timestamp=' + new Date().getTime())
      .then((response) => {

        this.poupup = response.data.poupup;
        this.poupup_link = response.data.poupup_link;
        this.slides = response.data.slides;

        if(this.poupup!='' && this.poupup!=null && this.poupup!=undefined){
          this.$refs['my-modal'].show();
        }

      })
      .catch(() => {
          

      });

    },
    carregaCartoes(){

      this.$axios.get(process.env.VUE_APP_URL+'cartoes')
      .then((response) => {
        const data = response.data;
        this.cartoes = data.cartoes;
      })
      .catch(() => {
          this.loading = false;
      });

      /*this.$axios.get('cartoes.json')
      .then((response) => {

        this.cartoes = response.data;

      })
      .catch(() => {
          

      });*/

    },
    carregaClientes(){

      this.$axios.get('clientes.json')
      .then((response) => {

        this.clientes = response.data;

      })
      .catch(() => {
          

      });

    },
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
  }
}
</script>