import Vue from 'vue'
import Vuex from 'vuex'

import site from './site/main.js'

Vue.use(Vuex)

const modules = {
    site
}

export default new Vuex.Store({
    modules
})