import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/fale-conosco',
    name: 'Fale Conosco',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaleConosco.vue')
  },
  {
    path: '/nossos-cartoes',
    name: 'Nossos Cartões',
    component: () => import(/* webpackChunkName: "about" */ '../views/NossosCartoes.vue')
  },
  {
    path: '/rede-credenciada',
    name: 'Rede Credenciada',
    component: () => import(/* webpackChunkName: "about" */ '../views/RedeCredenciada.vue')
  },
  {
    path: '/rede-credenciada/:url',
    name: 'Rede Credenciada',
    component: () => import(/* webpackChunkName: "about" */ '../views/RedeCredenciadaLista.vue')
  },
  {
    path: '/aceite-nossos-cartoes',
    name: 'Aceite',
    component: () => import(/* webpackChunkName: "about" */ '../views/Aceite.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "about" */ '../views/blog.vue')
  },
  {
    path: '/suporte',
    name: 'Suporte',
    component: () => import(/* webpackChunkName: "about" */ '../views/Suporte.vue')
  },
  {
    path: '/suporte/:url',
    name: 'Suporte Categoria',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuporteCategoria.vue')
  },
  {
    path: '/suporte/:categoria/:url',
    name: 'Suporte Categoria',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuportePagina.vue')
  },
  {
    path: '/:url',
    name: 'Page',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
