const SET_CIDADE = (state, obj) => {
    state.cidade = obj;
}
const SET_CARTAO = (state, cartao) => {
    state.cartao = cartao;
}

export default {
    SET_CIDADE,
    SET_CARTAO
}