import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store/store';

import { BootstrapVue, IconsPlugin,LayoutPlugin  } from 'bootstrap-vue'



// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import './assets/layout.css'
import VueTheMask from 'vue-the-mask'
import router from './router'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(LayoutPlugin)
Vue.use(VueTheMask)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
